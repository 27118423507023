import React, { useState, useEffect } from 'react'
import { css } from '@emotion/core'
import { useFormik } from 'formik'
import { object, string, ref } from 'yup'
import { colors, spacer } from '@dqp/common/styles/utilities'
import Button from '@dqp/common/components/Button/Button'
import Text, {
  ErrorText,
  SuccessText,
} from '@dqp/common/components/Text/Text'
import Link from '@dqp/common/components/Link/Link'
import TextField from '@dqp/common/components/TextField/TextField'
import PasswordField from '@dqp/common/components/PasswordField/PasswordField'
import { get } from 'lodash-es'
import routes from '#globals/routes'
import { useUpdateForgottenPassword } from '#api/auth'
import { apiStatuses } from '#globals/constants'
import parseValidationErrors from '#utils/parseValidationErrors'
import Stack from '#components/Stack/Stack'
import useParseQueryParams from '#utils/useParseQueryParams'
import getErrorMessage from '#utils/getErrorMessage'

const styles = {
  text: css`
    line-height: 1.5;
    ${spacer.mrT30};
  `,
}
const UpdateForgottenPasswordSchema = object().shape({
  email: string()
    .email('Invalid email.')
    .required('Email is required.'),
  password: string()
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .required('Password is required.'),
  password_confirmation: string()
    .required('Please confirm your password.')
    .oneOf([ref('password'), null], "Passwords don't match"),
})

function UpdateForgottenPasswordForm() {
  const { token, email } = useParseQueryParams()
  const { updateForgottenPassword } = useUpdateForgottenPassword()
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors: formErrors,
    isValid,
    touched,
    isSubmitting,
    resetForm,
  } = useFormik({
    validateOnBlur: true,
    validationSchema: UpdateForgottenPasswordSchema,
    initialValues: {
      email: email || '',
      password: '',
      password_confirmation: '',
    },

    onSubmit: async (formData, actions) => {
      try {
        const { data } = await updateForgottenPassword({
          variables: {
            ...formData,
            token,
          },
        })
        const { status, message } = get(
          data,
          'updateForgottenPassword',
          {},
        )
        if (
          status ===
          apiStatuses.updateForgottenPassword.PASSWORD_NOT_UPDATED
        ) {
          setErrorMessage(message)
        } else {
          resetForm()
          setSuccessMessage(message)
        }
      } catch (error) {
        const validationErrors = parseValidationErrors(error)
        const message = getErrorMessage(error)
        if (validationErrors) actions.setErrors(validationErrors)
        else if (message) setErrorMessage(message)
      }
    },
  })
  useEffect(() => {
    setErrorMessage('')
  }, [values])
  return (
    <form onSubmit={handleSubmit}>
      <fieldset disabled={!!successMessage}>
        <Stack spacing={20}>
          <div>
            <TextField
              name='email'
              label='Email'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              error={touched.email && formErrors.email}
              disabled
            />
          </div>

          <div>
            <PasswordField
              name='password'
              label='Password'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              error={touched.password && formErrors.password}
            />
          </div>

          <div>
            <PasswordField
              name='password_confirmation'
              label='Confirm Password'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password_confirmation}
              error={
                touched.password_confirmation &&
                formErrors.password_confirmation
              }
            />
          </div>

          {errorMessage && <ErrorText>{errorMessage}</ErrorText>}

          {successMessage && (
            <SuccessText>{successMessage}</SuccessText>
          )}

          <div
            className='d-flex align-items-center justify-content-between'
            css={styles.text}
          >
            <Button
              size='medium'
              variant='black'
              disabled={!isValid || isSubmitting}
              isLoading={isSubmitting}
              type='submit'
            >
              UPDATE PASSWORD
            </Button>
            <Text
              as='strong'
              size='small'
              color={colors.black}
              className='d-sm-flex d-none'
              weight='bold'
            >
              <Link to={routes.login.to} borderColor={colors.black}>
                Return to login
              </Link>
            </Text>
          </div>
        </Stack>
      </fieldset>
    </form>
  )
}

export default UpdateForgottenPasswordForm
